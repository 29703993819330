<script setup lang="ts">
import { fetchPreviewExchangeOrder } from "@/api";
import {
  formatNumberWithSpaces,
  numberExponentToLarge,
  cryptoConvert,
} from "@/shared/utils";
const { t } = useI18n();
const dayjs = useDayjs();
const RatesStore = useRatesStore();
const { getRates } = RatesStore;
const { refreshDate } = storeToRefs(RatesStore);
const CurrencyStore = useCurrencyStore();
const { getSympolById } = CurrencyStore;

const emit = defineEmits(["refresh"]);

type Props = {
  send: any;
  recieve: any;
};

const props = defineProps<Props>();

const stringRefreshDate = ref<string | null>(null);
useInterval(1000, {
  callback: async () => {
    if (!refreshDate.value) {
      return;
    }
    const now = dayjs();
    const targetDate = dayjs(refreshDate.value);

    const diffInSeconds = targetDate.diff(now, "second");

    if (diffInSeconds <= 0) {
      stringRefreshDate.value = t("p-exchange-rates-updated-now");
      await localRateFetch();
      await getRates();
      emit("refresh");
      return;
    }

    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = diffInSeconds % 60;

    stringRefreshDate.value = t("p-exchange-rates-updated-in", {
      minutes: minutes,
      seconds: `${seconds < 10 ? "0" : ""}${seconds}`,
    });
  },
});

const localRate = ref<any>(null);

const localRateFetch = async () => {
  let res = null;
  const from = props.send?.bpoId ? props.send?.bpoId : props.send?.id;
  const to = props.recieve?.bpoId ? props.recieve?.bpoId : props.recieve?.id;

  if (props.send !== "" && props.recieve !== "" && props.recieve !== "...") {
    localRate.value = null;
    const model: Record<string, string | number> = {
      from,
      to,
    };
    if (props.send?.bpoId) {
      model.amountTo = cryptoConvert(1, props.recieve?.name);
    } else {
      model.amountFrom = cryptoConvert(1, props.send?.name);
    }
    try {
      res = await fetchPreviewExchangeOrder(model);
    } catch (e) {}
  }
  localRate.value = props.send?.bpoId
    ? res?.amountFrom / 100
    : res?.amountTo / 100;
};

const watcher = watch([() => props.send, () => props.recieve], async () => {
  await localRateFetch();
});

onBeforeUnmount(() => {
  watcher();
});
</script>

<template>
  <ClientOnly>
    <template #fallback>
      <el-skeleton animated :rows="1" />
    </template>
    <p
      class="transition-par par-size-XS mb-2"
      :class="{
        'opacity-0': !localRate,
      }"
    >
      {{ t("p-exchange-current-rate") }}
      <Transition name="fade">
        <span
          class="color-warning whitespace-nowrap"
          v-if="
            localRate &&
            (send?.id || recieve?.id) &&
            (send?.bpoId || recieve?.bpoId)
          "
        >
          1
          {{ send?.currency ? recieve?.name : send?.name }}
          =
          <span class="">
            {{ formatNumberWithSpaces(numberExponentToLarge(localRate, 2)) }}
            {{
              recieve?.currency
                ? getSympolById(recieve?.currency)
                : getSympolById(send?.currency || send)
            }}
          </span>
        </span>
      </Transition>
    </p>
    <Transition name="fade">
      <span v-if="stringRefreshDate" class="opacity-55 par-size-XS"
        >{{ stringRefreshDate }}
      </span>
    </Transition>
  </ClientOnly>
</template>

<style scoped lang="scss">
.transition-par {
  transition: all 0.3s ease-in;
}
</style>
